import {
  AlternativeProductFootprintResult,
  ProductAlternativesFormProps,
  ProductGroupWithAlternative,
} from '../../../persistence/model/AlternativeProducts.ts';

export const calculateResult = (form: ProductAlternativesFormProps, group: ProductGroupWithAlternative): AlternativeProductFootprintResult => {
  const ledBulbBurningCost = 0.003944 // kg/CO2/h

  const footprintCalculation = (value: number) => {
    result.co2Percentages.total += value;
    result.bulbs = (result.co2Percentages.total ) * group.co2footprint * 1000 / ledBulbBurningCost
    result.co2 = (result.co2Percentages.total ) * group.co2footprint * 1000
  }

  const result: AlternativeProductFootprintResult = {
    bulbs: 0,
    co2: 0,
    co2Percentages: {
      co2Offset: 0,
      total: -group.productGroupAlternative.co2footprint / group.co2footprint + 1,
    },
  };

  if (form.isccCertifiedMaterialPercentage === 100) {
    footprintCalculation(group.iscc100 / 100)
  } else if (form.isccCertifiedMaterialPercentage === 50) {
    footprintCalculation(group.iscc50 / 100)
  } else if (form.isccCertifiedMaterialPercentage === 25) {
    footprintCalculation(group.iscc25 / 100)
  }

  if (form.thinnerPackage) {
    footprintCalculation(group.thinnerPackagePct / 100)
  }

  if (form.sustainablePrint) {
    footprintCalculation(group.sustainablePrint / group.co2footprint)
  }

  if (form.co2Offset) {
    result.co2Percentages.co2Offset = 100 - result.co2Percentages.total;
    result.co2Percentages.total = 100;
  }

  return result;
};
