import { Card, Checkbox, Col, Flex, Row, Select, Tooltip, Typography } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import FlexBox from '../../common/FlexBox.tsx';
import ProductAlternativesResult from './ProductAlternativesResult.tsx';
import { FormattedMessage, useIntl } from 'react-intl';
import { translate } from '../../../translations/TranslationUtils.ts';
import './ProductAlternatives.css';
import { ProductGroupWithAlternative, ProductAlternativesFormProps } from '../../../persistence/model/AlternativeProducts.ts';
import { useSelector } from 'react-redux';
import { localeSelector } from '../../../persistence/authSlice.ts';
import { Language } from '../../../persistence/model/Common.ts';
import StructureImage from './StructureImage.tsx';

const CardContent = ({ label, value, content }: { label: React.ReactNode | string; value?: string, content: React.ReactNode }): JSX.Element => (
  <FlexBox direction={'vertical'} gap={10}>
    <Typography.Text style={{ fontSize: '16px' }}>{label}<span style={{ fontWeight: 'bolder' }}>{value}</span></Typography.Text>
    {content}
  </FlexBox>
);

const AdditionalCard = ({ value, formItem, labelKey, disabled }: { labelKey: string; value: any; disabled?: boolean; formItem: JSX.Element }): JSX.Element => (
  <Card className={`${value && 'selected-border'} ${disabled && 'card-disabled'}`}>
    <CardContent
      label={
        <span className={`${disabled ? 'text-color-gray' : ''}`}>
          <FormattedMessage id={`productAlternatives.${labelKey}.title`} />
        </span>
      }
      content={
        <FlexBox direction={'vertical'}>
          {formItem}
          <div className="text-color-gray">
            <FormattedMessage id={`productAlternatives.${labelKey}.description`} />
          </div>
        </FlexBox>
      }
    />
  </Card>
);

export type ProductAlternativesProps = {
  group: ProductGroupWithAlternative;
};

const IsccOptions = [0, 25, 50, 100];

const ProductAlternatives = ({ group }: ProductAlternativesProps): JSX.Element => {
  const intl = useIntl();
  const locale = useSelector(localeSelector);
  const isEt = useMemo(() => locale === Language.ET, [locale]);
  const [form, setForm] = useState<ProductAlternativesFormProps>({ isccCertifiedMaterialPercentage: 0 });

  useEffect(() => {
    setForm({ isccCertifiedMaterialPercentage: 0 })
  }, [group]);

  const co2OffsetCard = () => {
    const enabled = form.isccCertifiedMaterialPercentage === 100;

    return (
        <AdditionalCard
          disabled={!enabled}
          value={enabled && form.co2Offset}
          labelKey={'co2Offset'}
          formItem={
            <Tooltip title={!enabled ? translate(intl, "productAlternatives.co2Offset.tooltip") : ''} placement={"topLeft"}>
            <Checkbox
              value={true}
              checked={enabled && form.co2Offset}
              disabled={!enabled}
              onChange={(e) => setForm({ ...form, co2Offset: e.target.checked })}
            >
              <FormattedMessage id={`productAlternatives.co2Offset.${form.co2Offset ? 'selected' : 'select'}`} />
            </Checkbox>
            </Tooltip>
          }
        />

    );
  };

  return (
    <Flex vertical={true} gap={20} style={{ width: '100%', marginBottom: '50px' }}>
      <Card>
        <CardContent
          label={translate(intl, 'productAlternatives.standardProduct')}
          value={isEt ? group.nameEt : group.nameEn}
          content={
            <FlexBox direction={'horizontal'} style={{ justifyContent: 'space-between' }}>
              <div className="text-color-gray">{isEt ? group.portalDescEe : group.portalDescEn}</div>
              <StructureImage imageId={group.imageId} />
            </FlexBox>
          }
        />
      </Card>
      <Card className="selected">
        <CardContent
          label={translate(intl, 'productAlternatives.alternativeProduct')}
          value={isEt ? group.productGroupAlternative.nameEt : group.productGroupAlternative.nameEn}
          content={
            <FlexBox direction={'horizontal'} style={{ justifyContent: 'space-between' }}>
              <div className="text-color-gray">{isEt ? group.productGroupAlternative.portalDescEe : group.productGroupAlternative.portalDescEn}</div>
              <StructureImage imageId={group.productGroupAlternative.imageId} />
            </FlexBox>
          }
        />
      </Card>
      <Typography.Text style={{ fontSize: '16px', fontWeight: 600, lineHeight: '22px' }}>
        <FormattedMessage id={`productAlternatives.additionalOptions`} />
      </Typography.Text>
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <AdditionalCard
            value={form.isccCertifiedMaterialPercentage}
            labelKey={'iscc'}
            formItem={
              <Select<number>
                value={form.isccCertifiedMaterialPercentage}
                placeholder={translate(intl, 'productAlternatives.iscc.selectPlaceholder')}
                onChange={(value) => setForm({ ...form, isccCertifiedMaterialPercentage: value, co2Offset: value === 100 ? form.co2Offset : false })}
                options={IsccOptions.map((option) => ({ label: option !== 100 ? `${option} %` : 'Max', value: option }))}
              />
            }
          />
        </Col>
        <Col span={12}>
          <AdditionalCard
            value={form.thinnerPackage}
            labelKey={'thinnerPackage'}
            formItem={
              <Checkbox value={true} checked={form.thinnerPackage}
                        onChange={(e) => setForm({ ...form, thinnerPackage: e.target.checked })}>
                <FormattedMessage id={`productAlternatives.thinnerPackage.${form.thinnerPackage ? 'selected' : 'select'}`} />
              </Checkbox>
            }
          />
        </Col>
        <Col span={12}>
          <AdditionalCard
            disabled={!group.productGroupAlternative.sustainablePrint}
            value={form.sustainablePrint}
            labelKey={'sustainablePrint'}
            formItem={
              <Checkbox value={true} checked={form.sustainablePrint} disabled={!group.productGroupAlternative.sustainablePrint}
                        onChange={(e) => setForm({ ...form, sustainablePrint: e.target.checked })}>
                <FormattedMessage id={`productAlternatives.sustainablePrint.${form.sustainablePrint ? 'selected' : 'select'}`} />
              </Checkbox>
            }
          />
        </Col>
        <Col span={12}>{co2OffsetCard()}</Col>
      </Row>
      <ProductAlternativesResult form={form} group={group} />
    </Flex>
  );
};

export default ProductAlternatives;
